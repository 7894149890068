.Reports {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker-wrapper {
    width: 11em;
}

.DatePicker {
    text-align: center;
}

.dateSelectContainer button {
    margin: 0.5em;
}

.dateSelectContainer {
    margin-right: 0px;
}