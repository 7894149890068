.ProjectManagement {
    /* width: 30%; */
    width: 600px;
    min-width: 600px;
    margin: auto;
}

.projectTable {
    background-color: #eee;
    margin-bottom: 10em;
}

.projectTable tbody tr th {
    font-size: 1.2em;
}

.projectTable tbody tr td, .projectTable tbody tr th {
    padding: 0.75em;
    font-weight: bold;
}

.newProjectBtn {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    margin-left: 1em;
}

.statusSelect {
    width: 10em;
    padding-right: -10px;
    margin-right: 0em;
    text-align: left;
}
