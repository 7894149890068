body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.smallSelect > div:first-of-type > div:first-child > div:nth-child(2) > div > input {
  height: 0rem !important;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.NavBar {
  text-align: left;
  height: 4.2rem;
}

.LoginPage {
  margin-top: 0px;
}

.InputContainer {
  margin-top: 50px;
  width: 350px;
}


.RegisterPage {
  margin-top: 0px;
}

.InputContainer {
  margin-top: 50px;
  width: 350px;
}

:root {
    --column-amount: 7;
}


.Calendar {
    /* background-color: rgb(177, 40, 40); */
    /* overflow: hidden; */
    min-width: 1000px;
}

.Calendar .CalendarHeader .DateHeader {
    background-color: #aca8a8;
}

.Calendar .CalendarGrid {
    background-color: #f8f7f7;
    display: grid;
    grid-template-columns: 1fr repeat(7, 2fr);
    grid-template-columns: 1fr repeat(var(--column-amount, 7), 2fr);
    grid-auto-rows: 18px;
    /* grid-gap: 5px; */
    width: 100%;
    max-height: calc(100vh - 13rem);
    /* max-height: 34rem; */
    overflow-y: show;
    
    overflow-x:hidden;
    min-width: 1000px;
    border: 1px solid grey;
}

.Calendar .CalendarHeader {
    background-color: rgb(253, 253, 253);
    display: grid;
    grid-template-columns: 1fr repeat(7, 2fr) auto;
    grid-template-columns: 1fr repeat(var(--column-amount, 7), 2fr) auto;
    /*grid-auto-rows: 25px;*/
    width: 100%;
    
    min-width: 1000px;
    overflow-y:hidden;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    /* border-bottom: 1px solid black; */
    margin-bottom: 0px;
    

    position: relative;
    z-index: 2;
    box-shadow: 0px 5px 5px -5px #333;
}

.CalendarTopBar {
    margin-bottom: 0px;
    background-color: rgb(253, 253, 253);
    height: 50px;
    width: 100%;
}


.CalendarTopBarLeft {
    padding-right: 0px;
}

.CalendarTopBarCenter {
    font-size: 24px;
}

.CalendarTopBarRight {
    padding-left: 0px;
}
.Timeblock {
	background-color: white;
	border: 1px solid #0079db;
	border-radius: 3px;
	position: relative;
}

.Timeblock:hover {
	background-color: #fff1eb;
}

.Timeblock .InteractableContent {
	position: absolute;
	top: 0px;
	left: 0px;
	cursor: move;
	width: 100%;
	height: 100%;
}

.Timeblock .ResizeTop {
	position: absolute;
	top: -5px;
	height: 10px;
	width: 100%;
	cursor: ns-resize;
	margin-left: -50%;
}

.Timeblock .ResizeBottom {
	position: absolute;
	bottom: -5px;
	height: 10px;
	width: 100%;
	cursor: ns-resize;
	margin-left: -50%;
}

.Timeblock .TextContent {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 96%;
	margin-left: 2%;
	pointer-events: none;
	height: 100%;
	overflow: hidden;
}

.Timeblock .TextContent p {
	margin-top: 0px;
}

.ProjectName {
	font-weight: bold;
	font-size: calc(12px + 0.5vmin);
}

.Desc {
	font-size: calc(9px + 0.5vmin);
	white-space: pre-line;
}

.Timeblock .timeblockNote {
	font-style: italic;
	color: #666
}


.DateHeader {
    background-color: #aba8a8;
    border-left: 1px solid black;
    /* border-right: 1px solid black; */
    width: 100%;
    height: 50px;
    overflow: none;
}
.AddTimeblock {
	overflow-y: auto;
	height: 100%;
}

.Popup {
	background-color: rgba(0,0,0,0.5);
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.Popup .PopupElement {
	box-shadow: 0 0 1px 1px rgba(0,0,0,0.3);
	width: 60%;
	height: 76%;
	margin: auto;
	margin-top: 12vh;
}

.Popup .PopupTopBar {
	width: 100%;
	height: 5em;
	background-color: #f3f3f3;
	overflow: hidden;
	margin-top: 0px;
	margin-bottom: 0px;
}

.Popup .PopupTitle {
	position: relative;
	display: inline-block;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-left: 6vh;
	padding-right: 0;
	font-size: 2em;
	font-weight: bold;
}

.PopupCloseImage {
	position: relative;
	float: right;
	margin: auto;
	margin-right: 1.25em;
	margin-top: 1.25em;
	cursor: pointer;
	height: 2.5em;
}

.Popup .PopupContent {
	background-color: #fff;
	height: 90%;
	top: 0px;
	overflow: hidden;
}

.UpdateTimeblockFields {
	/* overflow-y: scroll; */
	/* overflow-x: hidden; */
	/* height: 50%; */
	text-align: left;
	padding-left: 6%;
	width: 94%;
	font-size: calc(12px + 0.5vmin);
	/* background-color: blue; */
}

.inputTaskNo input {
    height: 2.2em !important;
    border: 1px solid grey !important;
    padding-left: 8px !important;
    font-weight: bold;
}

.inputNote textarea {
	resize: none;
	height: 11em;
	overflow-y: scroll;
}

.inputRate {
	margin-top: 1em;
	margin-bottom: 0.5em;
}

.react-datepicker__time-container, .react-datepicker__time-box {
	width: 90px !important;
}

.react-datepicker__time-list-item {
	height: 18px !important;
}


.EditTimeblock {
	overflow-y: auto;
	height: 100%;
}
.ToggleEmployees {
	overflow-x: hidden;
	height: 100%;
	text-align: left;
	padding-left: 10%;
	width: 90%;
	/* font-size: 30px; */
}

.EmployeeList {
	margin-top: 40px;
	margin-left: 0%;
}

.CalendarGridCell {
	border-bottom: 1px solid black;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
}

.CalendarGridCell:hover{
		background-color: #fff1eb;
}

.Timesheets {
    height: 85vh;
    overflow-y: show;
}

.CalendarContainer {
    width: 97%;
    min-width: 30vw;
    margin: auto;
    margin-top: 15px;
    overflow-y: auto;
}
.Reports {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker-wrapper {
    width: 11em;
}

.DatePicker {
    text-align: center;
}

.dateSelectContainer button {
    margin: 0.5em;
}

.dateSelectContainer {
    margin-right: 0px;
}
.Summary {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.Summary .SummaryHeader {
    margin-bottom: 20px;
    margin-left: 3%;
    margin-right: 3%;
    padding-top: 20px;
}

.Summary .SummaryHeader .SummaryTitle {
    font-size: 30px;
}

.Summary .SummaryHeader .SummaryDesc {
    font-size: 18px;
}

.Summary .SummaryHeader .SummaryDownload {
    font-size: 18px;
}

.Summary .SummaryContent {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.Summary .TitleSummary {
    background-color: #eee;
    font-weight: bold;
    color: #555;
    padding-top: 5px;
    padding-bottom: 5px;
}

.Summary .UserSummary {
    margin-top: 40px; 
}

.Summary .UserSummaryTitle {
    font-weight: bold;
    border-bottom: 1px rgba(0,0,0,0.1) solid;
    margin-bottom: 0px;
}

.Summary .ProjectSummary {
    margin-left: 1%;
    margin-top: 12px;
    margin-bottom: 2px;
}

.Summary .individualSummaryLink, .Summary .individualProjectSummaryLink {
    color: black;
}

.Summary .TaskNoSummary {
    padding-left: 3em;
    margin-bottom: 0px;
    color: grey;
    font-size: 0.9em;
}


.IndividualSummary {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.IndividualSummary .aside {
  color: red;
  font-weight: 50;
  font-style: italic;
}

.IndividualSummary .header {
    margin-bottom: 20px;
    margin-left: 3%;
    padding-top: 20px;
}

.IndividualSummary .header .title {
    font-size: 30px;
}

.IndividualSummary .header .desc {
    font-size: 18px;
}


.IndividualSummary .content {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-line;
}

.IndividualSummary .content .overallBar {
    background-color: #eee;
    font-weight: bold;
    color: #555;
    padding-top: 5px;
    padding-bottom: 5px;
}

.IndividualSummary .project .projectTitle {
    font-weight: bold;
    border-bottom: 1px rgba(0,0,0,0.1) solid;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

.IndividualSummary .individualProjectSummaryLink {
    color: black;
}

.TaskBreakdown {
    margin-bottom: 0px;
    margin-left: 3%;
    width: 97%;
}

.TaskBreakdown .timeblockSummary {
    margin-left: 3%;
    width: 97%;
}

.TaskBreakdown .timeblockSummary .note {
    font-style: italic;
    color: #555;
}

.TaskBreakdown .taskTitle {
    font-weight: bold;
}

.TaskBreakdown .expanded-icon {
    display: inline;
    position: absolute;
    left: 2.2em;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #bbb;
}

.IndividualProjectSummary {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.IndividualProjectSummary .aside {
  color: red;
  font-weight: 50;
  font-style: italic;
}

.IndividualProjectSummary .header {
    margin-bottom: 20px;
    margin-left: 3%;
    margin-right: 3%;
    padding-top: 20px;
}

.IndividualProjectSummary .header .title div {
    font-size: 30px;
    padding: 0px;
}

.IndividualProjectSummary .header .title div:nth-child(2) {
    font-style: italic;
    font-weight: bold;
}

.IndividualProjectSummary .header .desc {
    font-size: 18px;
}


.IndividualProjectSummary .content {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-line;
}

.IndividualProjectSummary .content .overallBar {
    background-color: #eee;
    font-weight: bold;
    color: #555;
    padding-top: 5px;
    padding-bottom: 5px;
}

.IndividualProjectSummary .project .projectTitle {
    font-weight: bold;
    border-bottom: 1px rgba(0,0,0,0.1) solid;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

.ProjectSummary {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.ProjectSummary .aside {
  color: red;
  font-weight: 50;
  font-style: italic;
}

.ProjectSummary .Header {
    margin-bottom: 20px;
    margin-left: 3%;
    padding-top: 20px;
}

.ProjectSummary .Header .Title {
    font-size: 30px;
}

.ProjectSummary .Header .Desc {
    font-size: 18px;
}


.ProjectSummary .Content {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.ProjectSummary .Content .OverallBar {
    background-color: #eee;
    font-weight: bold;
    color: #555;
    padding-top: 5px;
    padding-bottom: 5px;
}



.Project .ProjectTitle {
    font-weight: bold;
    border-bottom: 1px rgba(0,0,0,0.1) solid;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

.Project .Task {
  margin-bottom: 0px;
  margin-left: 3%;
  width: 97%;
}

.Project .TaskTitle {
  font-weight: bold;
}

.Project .Task .TimeblockSummary {
  margin-left: 3%;
  width: 97%;
}

.ProjectManagement {
    /* width: 30%; */
    width: 600px;
    min-width: 600px;
    margin: auto;
}

.projectTable {
    background-color: #eee;
    margin-bottom: 10em;
}

.projectTable tbody tr th {
    font-size: 1.2em;
}

.projectTable tbody tr td, .projectTable tbody tr th {
    padding: 0.75em;
    font-weight: bold;
}

.newProjectBtn {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    margin-left: 1em;
}

.statusSelect {
    width: 10em;
    padding-right: -10px;
    margin-right: 0em;
    text-align: left;
}

.NewProject {
	overflow-y: auto;
	height: 100%;
}


.projectNameInputDiv {
	width: 50%;
	margin: auto;
	display: inline-block;
	margin-left: 2em;
}
.IndividualProject {
    width: 600px;
    min-width: 600px;
    margin: auto;
    margin-top: 1em;
}

.IndividualProject .projectName {
    font-size: 1.5em;
    margin-bottom: 1em;
    font-weight: bold;
}

.taskTable {
    background-color: #eee;
    margin-bottom: 10em;
}

.taskTable tbody tr th {
    font-size: 1.2em;
}

.taskTable tbody tr td, .taskTable tbody tr th {
    padding: 0.75em;
    font-weight: bold;
}

