.ToggleEmployees {
	overflow-x: hidden;
	height: 100%;
	text-align: left;
	padding-left: 10%;
	width: 90%;
	/* font-size: 30px; */
}

.EmployeeList {
	margin-top: 40px;
	margin-left: 0%;
}