.IndividualSummary {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.IndividualSummary .aside {
  color: red;
  font-weight: 50;
  font-style: italic;
}

.IndividualSummary .header {
    margin-bottom: 20px;
    margin-left: 3%;
    padding-top: 20px;
}

.IndividualSummary .header .title {
    font-size: 30px;
}

.IndividualSummary .header .desc {
    font-size: 18px;
}


.IndividualSummary .content {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    white-space: pre-line;
}

.IndividualSummary .content .overallBar {
    background-color: #eee;
    font-weight: bold;
    color: #555;
    padding-top: 5px;
    padding-bottom: 5px;
}

.IndividualSummary .project .projectTitle {
    font-weight: bold;
    border-bottom: 1px rgba(0,0,0,0.1) solid;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

.IndividualSummary .individualProjectSummaryLink {
    color: black;
}
