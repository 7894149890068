.ProjectSummary {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.ProjectSummary .aside {
  color: red;
  font-weight: 50;
  font-style: italic;
}

.ProjectSummary .Header {
    margin-bottom: 20px;
    margin-left: 3%;
    padding-top: 20px;
}

.ProjectSummary .Header .Title {
    font-size: 30px;
}

.ProjectSummary .Header .Desc {
    font-size: 18px;
}


.ProjectSummary .Content {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.ProjectSummary .Content .OverallBar {
    background-color: #eee;
    font-weight: bold;
    color: #555;
    padding-top: 5px;
    padding-bottom: 5px;
}



.Project .ProjectTitle {
    font-weight: bold;
    border-bottom: 1px rgba(0,0,0,0.1) solid;
    padding-bottom: 15px;
    margin-bottom: 10px;
}

.Project .Task {
  margin-bottom: 0px;
  margin-left: 3%;
  width: 97%;
}

.Project .TaskTitle {
  font-weight: bold;
}

.Project .Task .TimeblockSummary {
  margin-left: 3%;
  width: 97%;
}
