.Summary {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.Summary .SummaryHeader {
    margin-bottom: 20px;
    margin-left: 3%;
    margin-right: 3%;
    padding-top: 20px;
}

.Summary .SummaryHeader .SummaryTitle {
    font-size: 30px;
}

.Summary .SummaryHeader .SummaryDesc {
    font-size: 18px;
}

.Summary .SummaryHeader .SummaryDownload {
    font-size: 18px;
}

.Summary .SummaryContent {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.Summary .TitleSummary {
    background-color: #eee;
    font-weight: bold;
    color: #555;
    padding-top: 5px;
    padding-bottom: 5px;
}

.Summary .UserSummary {
    margin-top: 40px; 
}

.Summary .UserSummaryTitle {
    font-weight: bold;
    border-bottom: 1px rgba(0,0,0,0.1) solid;
    margin-bottom: 0px;
}

.Summary .ProjectSummary {
    margin-left: 1%;
    margin-top: 12px;
    margin-bottom: 2px;
}

.Summary .individualSummaryLink, .Summary .individualProjectSummaryLink {
    color: black;
}

.Summary .TaskNoSummary {
    padding-left: 3em;
    margin-bottom: 0px;
    color: grey;
    font-size: 0.9em;
}

