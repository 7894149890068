.UpdateTimeblockFields {
	/* overflow-y: scroll; */
	/* overflow-x: hidden; */
	/* height: 50%; */
	text-align: left;
	padding-left: 6%;
	width: 94%;
	font-size: calc(12px + 0.5vmin);
	/* background-color: blue; */
}

.inputTaskNo input {
    height: 2.2em !important;
    border: 1px solid grey !important;
    padding-left: 8px !important;
    font-weight: bold;
}

.inputNote textarea {
	resize: none;
	height: 11em;
	overflow-y: scroll;
}

.inputRate {
	margin-top: 1em;
	margin-bottom: 0.5em;
}

.react-datepicker__time-container, .react-datepicker__time-box {
	width: 90px !important;
}

.react-datepicker__time-list-item {
	height: 18px !important;
}

