.Popup {
	background-color: rgba(0,0,0,0.5);
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.Popup .PopupElement {
	box-shadow: 0 0 1px 1px rgba(0,0,0,0.3);
	width: 60%;
	height: 76%;
	margin: auto;
	margin-top: 12vh;
}

.Popup .PopupTopBar {
	width: 100%;
	height: 5em;
	background-color: #f3f3f3;
	overflow: hidden;
	margin-top: 0px;
	margin-bottom: 0px;
}

.Popup .PopupTitle {
	position: relative;
	display: inline-block;
	margin-top: 0px;
	margin-bottom: 0px;
	padding-left: 6vh;
	padding-right: 0;
	font-size: 2em;
	font-weight: bold;
}

.PopupCloseImage {
	position: relative;
	float: right;
	margin: auto;
	margin-right: 1.25em;
	margin-top: 1.25em;
	cursor: pointer;
	height: 2.5em;
}

.Popup .PopupContent {
	background-color: #fff;
	height: 90%;
	top: 0px;
	overflow: hidden;
}
