.TaskBreakdown {
    margin-bottom: 0px;
    margin-left: 3%;
    width: 97%;
}

.TaskBreakdown .timeblockSummary {
    margin-left: 3%;
    width: 97%;
}

.TaskBreakdown .timeblockSummary .note {
    font-style: italic;
    color: #555;
}

.TaskBreakdown .taskTitle {
    font-weight: bold;
}

.TaskBreakdown .expanded-icon {
    display: inline;
    position: absolute;
    left: 2.2em;
    cursor: pointer;
    user-select: none;
    color: #bbb;
}
