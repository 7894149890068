:root {
    --column-amount: 7;
}


.Calendar {
    /* background-color: rgb(177, 40, 40); */
    /* overflow: hidden; */
    min-width: 1000px;
}

.Calendar .CalendarHeader .DateHeader {
    background-color: #aca8a8;
}

.Calendar .CalendarGrid {
    background-color: #f8f7f7;
    display: grid;
    grid-template-columns: 1fr repeat(var(--column-amount, 7), 2fr);
    grid-auto-rows: 18px;
    /* grid-gap: 5px; */
    width: 100%;
    max-height: calc(100vh - 13rem);
    /* max-height: 34rem; */
    overflow-y: show;
    
    overflow-x:hidden;
    min-width: 1000px;
    border: 1px solid grey;
}

.Calendar .CalendarHeader {
    background-color: rgb(253, 253, 253);
    display: grid;
    grid-template-columns: 1fr repeat(var(--column-amount, 7), 2fr) auto;
    /*grid-auto-rows: 25px;*/
    width: 100%;
    
    min-width: 1000px;
    overflow-y:hidden;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-top: 1px solid black;
    /* border-bottom: 1px solid black; */
    margin-bottom: 0px;
    

    position: relative;
    z-index: 2;
    box-shadow: 0px 5px 5px -5px #333;
}

.CalendarTopBar {
    margin-bottom: 0px;
    background-color: rgb(253, 253, 253);
    height: 50px;
    width: 100%;
}


.CalendarTopBarLeft {
    padding-right: 0px;
}

.CalendarTopBarCenter {
    font-size: 24px;
}

.CalendarTopBarRight {
    padding-left: 0px;
}