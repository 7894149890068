.IndividualProject {
    width: 600px;
    min-width: 600px;
    margin: auto;
    margin-top: 1em;
}

.IndividualProject .projectName {
    font-size: 1.5em;
    margin-bottom: 1em;
    font-weight: bold;
}

.taskTable {
    background-color: #eee;
    margin-bottom: 10em;
}

.taskTable tbody tr th {
    font-size: 1.2em;
}

.taskTable tbody tr td, .taskTable tbody tr th {
    padding: 0.75em;
    font-weight: bold;
}
