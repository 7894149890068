.NewProject {
	overflow-y: auto;
	height: 100%;
}


.projectNameInputDiv {
	width: 50%;
	margin: auto;
	display: inline-block;
	margin-left: 2em;
}