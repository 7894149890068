.Timesheets {
    height: 85vh;
    overflow-y: show;
}

.CalendarContainer {
    width: 97%;
    min-width: 30vw;
    margin: auto;
    margin-top: 15px;
    overflow-y: auto;
}