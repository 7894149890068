.Timeblock {
	background-color: white;
	border: 1px solid #0079db;
	border-radius: 3px;
	position: relative;
}

.Timeblock:hover {
	background-color: #fff1eb;
}

.Timeblock .InteractableContent {
	position: absolute;
	top: 0px;
	left: 0px;
	cursor: move;
	width: 100%;
	height: 100%;
}

.Timeblock .ResizeTop {
	position: absolute;
	top: -5px;
	height: 10px;
	width: 100%;
	cursor: ns-resize;
	margin-left: -50%;
}

.Timeblock .ResizeBottom {
	position: absolute;
	bottom: -5px;
	height: 10px;
	width: 100%;
	cursor: ns-resize;
	margin-left: -50%;
}

.Timeblock .TextContent {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 96%;
	margin-left: 2%;
	pointer-events: none;
	height: 100%;
	overflow: hidden;
}

.Timeblock .TextContent p {
	margin-top: 0px;
}

.ProjectName {
	font-weight: bold;
	font-size: calc(12px + 0.5vmin);
}

.Desc {
	font-size: calc(9px + 0.5vmin);
	white-space: pre-line;
}

.Timeblock .timeblockNote {
	font-style: italic;
	color: #666
}
