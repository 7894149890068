
.CalendarGridCell {
	border-bottom: 1px solid black;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
}

.CalendarGridCell:hover{
		background-color: #fff1eb;
}
